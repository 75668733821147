import React from 'react'
import { Link } from 'gatsby'
import { Accordion, Button } from 'react-bootstrap'
import footerLogo from '../images/wsc_logo_new.png'
import footerImg1 from '../images/cpa_logo_practice3.png'
import footerImg2 from '../images/xero-goldpartner-logo.png'
import footerImg3 from '../images/phrase_excellence3.png'
import upArrow from '../images/up-arrow-inside-circle.png'

class Footer extends React.Component {
  render() {
    const news = this.props.data
    console.log(news)
    return (
      <footer>
        <section className="section" id="copyright">
          <div className="container">
            <div className="row">
              <div className="col-md-4 behind text-left">
                <img src={footerLogo} alt="WSC Group Footer Logo" />
                <div className="colapscont">
                  <Accordion>
                    <ul className="eq-ui-collapsible m-0">
                      <li className="eq-ui-collapsible-header">
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey="1"
                        >
                          <i
                            className="fa fa-location-arrow"
                            aria-hidden="true"
                          />{' '}
                          NSW - SYDNEY SOUTH (HEAD OFFICE)
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="1">
                          <div className="eq-ui-collapsible-body">
                            <div className="eq-ui-collapsible-body-inner">
                              <ul className="listcontdet">
                                <li>
                                  <i
                                    className="fa fa-map-o"
                                    aria-hidden="true"
                                  />
                                  Unit 11, 800-812 Old Illawarra Road, Menai
                                  2234
                                  <br />
                                  PO Box 3070 Bangor NSW 2234
                                </li>
                                <li>
                                  <i
                                    className="fa fa-phone"
                                    aria-hidden="true"
                                  />
                                  <a href="tel:02 8525 4600">02 8525 4600</a>
                                </li>
                                <li>
                                  <i className="fa fa-fax" aria-hidden="true" />
                                  <a href="tel:02 8525 4650">02 8525 4650</a>
                                </li>
                                <li>
                                  <i
                                    className="fa fa-map-signs"
                                    aria-hidden="true"
                                  />
                                  50 350 478 852
                                </li>
                              </ul>
                            </div>
                          </div>
                        </Accordion.Collapse>
                      </li>
                      <li className="eq-ui-collapsible-header">
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey="2"
                        >
                          <i
                            className="fa fa-location-arrow"
                            aria-hidden="true"
                          />{' '}
                          NSW - NEWCASTLE
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="2">
                          <div className="eq-ui-collapsible-body">
                            <div className="eq-ui-collapsible-body-inner">
                              <ul className="listcontdet">
                                <li>
                                  <i
                                    className="fa fa-map-o"
                                    aria-hidden="true"
                                  />
                                  24 Alma Road, New Lambton 2305
                                  <br />
                                  PO Box 245 Kotara NSW 2289
                                </li>
                                <li>
                                  <i
                                    className="fa fa-phone"
                                    aria-hidden="true"
                                  />
                                  <a href="tel:02 4002 4826">02 4002 4826</a>
                                </li>
                                <li>
                                  <i className="fa fa-fax" aria-hidden="true" />
                                  <a href="tel:02 8525 4650">02 8525 4650</a>
                                </li>
                                <li>
                                  <i
                                    className="fa fa-map-signs"
                                    aria-hidden="true"
                                  />
                                  97 208 776 059
                                </li>
                              </ul>
                            </div>
                          </div>
                        </Accordion.Collapse>
                      </li>
                      <li className="eq-ui-collapsible-header">
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey="3"
                        >
                          <i
                            className="fa fa-location-arrow"
                            aria-hidden="true"
                          />{' '}
                          NSW – SYDNEY CBD
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="3">
                          <div className="eq-ui-collapsible-body">
                            <div className="eq-ui-collapsible-body-inner">
                              <ul className="listcontdet">
                                <li>
                                  <i
                                    className="fa fa-map-o"
                                    aria-hidden="true"
                                  />
                                  Level 8, 33-35 York Street, Sydney <br />
                                  PO Box 3070 Bangor NSW 2234
                                </li>
                                <li>
                                  <i
                                    className="fa fa-phone"
                                    aria-hidden="true"
                                  />
                                  <a href="tel:1300 135 125">1300 135 125</a>
                                </li>
                                <li>
                                  <i
                                    className="fa fa-map-signs"
                                    aria-hidden="true"
                                  />
                                  50 350 478 852
                                </li>
                              </ul>
                            </div>
                          </div>
                        </Accordion.Collapse>
                      </li>
                      <li className="eq-ui-collapsible-header">
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey="4"
                        >
                          <i
                            className="fa fa-location-arrow"
                            aria-hidden="true"
                          />{' '}
                          QLD - BRISBANE
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="4">
                          <div className="eq-ui-collapsible-body">
                            <div className="eq-ui-collapsible-body-inner">
                              <ul className="listcontdet">
                                <li>
                                  <i
                                    className="fa fa-map-o"
                                    aria-hidden="true"
                                  />
                                  Unit 22D, 1631 Wynnum Road, Tingalpa 4173
                                  <br />
                                  PO Box 299 Cannon Hill QLD 4170
                                </li>
                                <li>
                                  <i
                                    className="fa fa-phone"
                                    aria-hidden="true"
                                  />
                                  <a href="tel:07 3292 9600">07 3292 9600</a>
                                </li>
                                <li>
                                  <i className="fa fa-fax" aria-hidden="true" />
                                  <a href="tel:07 3323 3200">07 3323 3200</a>
                                </li>
                                <li>
                                  <i
                                    className="fa fa-map-signs"
                                    aria-hidden="true"
                                  />
                                  24 413 092 696
                                </li>
                              </ul>
                            </div>
                          </div>
                        </Accordion.Collapse>
                      </li>
                      <li className="eq-ui-collapsible-header">
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey="5"
                        >
                          <i
                            className="fa fa-location-arrow"
                            aria-hidden="true"
                          />{' '}
                          QLD - GOLD COAST
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="5">
                          <div className="eq-ui-collapsible-body">
                            <div className="eq-ui-collapsible-body-inner">
                              <ul className="listcontdet">
                                <li>
                                  <i
                                    className="fa fa-map-o"
                                    aria-hidden="true"
                                  />
                                  Suite 2D, 109 Upton Street, Bundall 4217
                                  <br />
                                  PO Box 7947, Gold Coast MC QLD 9726
                                </li>
                                <li>
                                  <i
                                    className="fa fa-phone"
                                    aria-hidden="true"
                                  />
                                  <a href="tel:07 5574 1358">07 5574 1358</a>
                                </li>
                                <li>
                                  <i className="fa fa-fax" aria-hidden="true" />
                                  <a href="tel:07 3323 3200">07 3323 3200</a>
                                </li>
                                <li>
                                  <i
                                    className="fa fa-map-signs"
                                    aria-hidden="true"
                                  />
                                  24 413 092 696
                                </li>
                              </ul>
                            </div>
                          </div>
                        </Accordion.Collapse>
                      </li>
                      <li className="eq-ui-collapsible-header">
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey="6"
                        >
                          <i
                            className="fa fa-location-arrow"
                            aria-hidden="true"
                          />{' '}
                          VIC – MELBOURNE
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="6">
                          <div className="eq-ui-collapsible-body">
                            <div className="eq-ui-collapsible-body-inner">
                              <ul className="listcontdet">
                                <li>
                                  <i
                                    className="fa fa-map-o"
                                    aria-hidden="true"
                                  />
                                  Suite 213, 757 Bourke Street, Docklands VIC
                                  3008
                                  <br />
                                  PO Box 420, South Yarra VIC 3141
                                </li>
                                <li>
                                  <i
                                    className="fa fa-phone"
                                    aria-hidden="true"
                                  />
                                  <a href="tel:03 9112 1000">03 9112 1000</a>
                                </li>
                                <li>
                                  <i className="fa fa-fax" aria-hidden="true" />
                                  <a href="tel:03 8677 2826">03 8677 2826</a>
                                </li>
                                <li>
                                  <i
                                    className="fa fa-map-signs"
                                    aria-hidden="true"
                                  />
                                  85 367 948 102
                                </li>
                              </ul>
                            </div>
                          </div>
                        </Accordion.Collapse>
                      </li>
                      <li className="eq-ui-collapsible-header">
                        <Accordion.Toggle
                          as={Button}
                          variant="link"
                          eventKey="7"
                        >
                          <i
                            className="fa fa-location-arrow"
                            aria-hidden="true"
                          />{' '}
                          WA - PERTH
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="7">
                          <div className="eq-ui-collapsible-body">
                            <div className="eq-ui-collapsible-body-inner">
                              <ul className="listcontdet">
                                <li>
                                  <i
                                    className="fa fa-map-o"
                                    aria-hidden="true"
                                  />
                                  PO Box 3070 Bangor NSW 2234
                                </li>
                                <li>
                                  <i
                                    className="fa fa-phone"
                                    aria-hidden="true"
                                  />
                                  <a href="tel:1300 365 125">1300 365 125</a>
                                </li>
                                <li>
                                  <i className="fa fa-fax" aria-hidden="true" />
                                  <a href="tel:(02) 8525 4650">
                                    (02) 8525 4650
                                  </a>
                                </li>
                                <li>
                                  <i
                                    className="fa fa-map-signs"
                                    aria-hidden="true"
                                  />
                                  28 536 175 476
                                </li>
                              </ul>
                            </div>
                          </div>
                        </Accordion.Collapse>
                      </li>
                    </ul>
                  </Accordion>
                </div>
              </div>
              <div className="col-md-3 quicklnks text-left">
                <h4>SERVICES</h4>
                <ul className="lnks">
                  <li>
                    <Link to="/our-services/business-advisory-services">
                      Business Advisory
                    </Link>
                  </li>
                  <li>
                    <Link to="/our-services/property-investment">
                      Property Investment
                    </Link>
                  </li>
                  <li>
                    <Link to="/our-services/superannuation">
                      Superannuation
                    </Link>
                  </li>
                  <li>
                    <Link to="/our-services/audit">Audit</Link>
                  </li>
                  <li>
                    <Link to="/our-services/financial-planning">
                      Financial Planning
                    </Link>
                  </li>
                  <li>
                    <Link to="/our-services/loans">Loans</Link>
                  </li>
                  <li>
                    <Link to="/our-services/xero">Cloud Accounting</Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-3 latestnews text-left">
                <div className="row">
                  <h4>LATEST NEWS </h4>
                  <div className="col-md-12 text-left">
                    {news.map(nws => (
                      <div className="news-items" key={nws.node.id}>
                        <div className="row">
                          <div className="col-md-3 text-left">
                            <div className="lead-sec">
                              <div className="lead-image">
                                <img
                                  className="img-responsive"
                                  src={nws.node.mainImage.asset.fluid.src}
                                  alt="Latest News"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-9 text-left">
                            <div className="blog-det">
                              <h3 className="blog-title">
                                <Link to={nws.node.slug.current}>
                                  {nws.node.title}
                                </Link>
                              </h3>
                              <div className="short-desc hidden">
                                <p />
                              </div>
                              <div className="published">
                                <i
                                  className="fa fa-clock-o"
                                  aria-hidden="true"
                                />
                                <p className="d-inline">
                                  <date> {nws.node._createdAt}</date>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className="col-sm-12 btnmorelatest">
                    <Link to="/latest-news">MORE </Link>
                  </div>
                </div>
              </div>
              <div className="col-md-2 conts text-center">
                <ul className="cntdets">
                  <li>
                    <img src={footerImg1} alt="CPA Practice" />
                  </li>
                  <li>
                    <img src={footerImg2} alt="XERO" />
                  </li>
                  <li>
                    <img src={footerImg3} alt="Gold Partner" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <div id="footerbtm">
          <div className="container">
            <div className="row">
              <div className="col-md-5 col-sm-4 col-xs-12 text-left">
                <p>
                  © 2019 WSC Group - Aust Pty Ltd ATF WSC Group - Aust Unit
                  Trust
                </p>
              </div>
              <div className="col-md-4 col-sm-4 col-xs-12 text-center">
                <ul>
                  <li>
                    <Link to="/disclaimer">Disclaimer</Link>
                  </li>
                  <li>
                    <Link to="/privacy-statement">Privacy Statement</Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-3 col-sm-4 col-xs-12 text-right">
                <a href="/#page-top">
                  <img src={upArrow} alt="Goes Up Button" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}
export default Footer

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allSanityNews(limit: 2) {
      edges {
        node {
          id
          title
          slug {
            current
          }
          _createdAt(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`
