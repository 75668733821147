import React from 'react'
import { StaticQuery } from 'gatsby'
// import { rhythm, scale } from '../utils/typography'

// STYLING
import '../utils/bootstrap.css'
import '../utils/font-awesome.min.css'
import './index.css'
import '../pages/resources/resources.css'
import '../pages/about-us/about-us.css'

import Header from './Header'
import Footer from './Footer'

class Layout extends React.Component {
  render() {
    const { children } = this.props
    // const rootPath = `${__PATH_PREFIX__}/`

    return (
      <StaticQuery
        query={graphql`
          query HeadingQuery {
            allSanityNews(limit: 2, sort: { fields: _createdAt, order: ASC }) {
              edges {
                node {
                  id
                  title
                  slug {
                    current
                  }
                  mainImage {
                    asset {
                      fluid {
                        src
                      }
                    }
                  }
                  _createdAt(formatString: "MMMM DD, YYYY")
                }
              }
            }
          }
        `}
        render={data => (
          <div style={{ position: `relative` }}>
            <Header />
            {/*        <div className="container">{header}</div>*/}
            <div>{children}</div>
            <Footer data={data.allSanityNews.edges} />
          </div>
        )}
      />
    )
  }
}

export default Layout
