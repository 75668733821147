import React from 'react'
import { Link } from 'gatsby'

class RightSideGeneral extends React.Component {
	render() {
		return (
			<div>
				<div id="rightside-general-wsc">
					<ul className="listside">
						<li className="consultion">
							<a href="tel:1300 365 125">
								<img
									src="https://d33wubrfki0l68.cloudfront.net/img/wsc/981470c895429f41cee44883af4f2004ffce7cc8/talktous-btn.png"
									alt="Talk to understanding"
								/>
							</a>
						</li>
						<li className="consultion">
							<Link to="/about-us/book-a-free">
								<img
									src="https://d33wubrfki0l68.cloudfront.net/img/wsc/8dbc6b7c885a7e2cde6f92876981333cc9c32b4e/book-consultation.jpg"
									alt="Book a Free Initial Consultation"
								/>
							</Link>
						</li>
						<li className="events">
							<a href="/upcoming-events">
								<img
									src="https://d33wubrfki0l68.cloudfront.net/img/wsc/3d33217f6ca21e1490555f35db969c6aabed35a9/upcoming-events.jpg"
									alt="Upcoming Events"
								/>
							</a>
						</li>
						<li className="freetips">
							<a href="/about-us/free-download-tips">
								<img
									src="https://d33wubrfki0l68.cloudfront.net/img/wsc/008a5378df67b29a766e981b2a3b163b8c6c1db2/free-tips.jpg"
									alt="Free Tips"
								/>
							</a>
						</li>
					</ul>
				</div>
				<div id="rightside-general-wsc2">
					<h2 className="conectside">Connect with WSC Group</h2>
					<ul className="sidesocial">
						<li>
							<a
								href="https://www.linkedin.com/company/wsc-group?trk=tyah&amp;trkInfo=tas%3AWSC+Group%2Cidx%3A1-1-1"
								target="_blank"
								rel="noopener noreferrer"
							>
								{' '}
								<i aria-hidden="true" className="fa fa-linkedin"></i>
							</a>
						</li>
						<li>
							<a
								href="https://www.facebook.com/wsc.group.aus"
								target="_blank"
								rel="noopener noreferrer"
							>
								<i aria-hidden="true" className="fa fa-facebook"></i>
							</a>
						</li>
						<li>
							<a
								href="https://plus.google.com/+WscgroupAus"
								target="_blank"
								rel="noopener noreferrer"
							>
								<i aria-hidden="true" className="fa fa-google-plus"></i>
							</a>
						</li>
						<li>
							<a
								href="https://www.youtube.com/channel/UC1TGGfs2nQyPHSADVPA2ghQ"
								target="_blank"
								rel="noopener noreferrer"
							>
								<i aria-hidden="true" className="fa fa-youtube-play"></i>
							</a>
						</li>
					</ul>
				</div>
			</div>
		)
	}
}

export default RightSideGeneral
