import React from 'react'
import { Link } from 'gatsby'
import { Nav } from 'react-bootstrap'
import Collapsible from 'react-collapsible'
// import ScrollAnimation from 'react-animate-on-scroll'

import './menu.css'
import Logo from '../images/wsc_logo_new.png'

export default () => (
	<div className="navigation">
		<img className="mb-0" src={Logo} alt="Menu Logo" />
		<Nav className="flex-column">
			<div id="about-us-nav">
				<Collapsible trigger="About Us">
					<Nav.Item>
						<Link to="/about-us/firm-profile">Firm Profile</Link>
					</Nav.Item>
					<Nav.Item>
						<Link to="/about-us/who-are-we">Who Are We?</Link>
					</Nav.Item>
					<Nav.Item>
						<Link to="/about-us/our-directors">Our Directors</Link>
					</Nav.Item>
				</Collapsible>
			</div>

			<Collapsible trigger="Our Services">
				<Collapsible className="inside-collapsible" trigger="Business Advisory">
					<Nav.Item className="inside-collapsible-menu">
						<Link to="/our-services/business-advisory-services">
							Business Advisory Services
						</Link>
					</Nav.Item>
					<Nav.Item className="inside-collapsible-menu">
						<Link to="/our-services/tax-consulting">Tax Consulting</Link>
					</Nav.Item>
					<Nav.Item className="inside-collapsible-menu">
						<Link to="/our-services/accounting">Accounting</Link>
					</Nav.Item>
					<Nav.Item className="inside-collapsible-menu">
						<Link to="/our-services/management-consulting">
							Management Consulting
						</Link>
					</Nav.Item>
					<Nav.Item className="inside-collapsible-menu">
						<Link to="/our-services/corporate-secretarial-services">
							Corporate Secretarial Services
						</Link>
					</Nav.Item>
				</Collapsible>
				<Collapsible
					className="inside-collapsible"
					trigger="Property Investment"
				>
					<Nav.Item className="inside-collapsible-menu">
						<Link to="/our-services/property-investment">
							Property Investment
						</Link>
					</Nav.Item>
					<Nav.Item className="inside-collapsible-menu">
						<Link to="/our-services/property-investment-club">
							Property Investor Club
						</Link>
					</Nav.Item>
				</Collapsible>
				<Nav.Item>
					<Link to="/our-services/superannuation">Superannuation</Link>
				</Nav.Item>
				<Nav.Item>
					<Link to="/our-services/audit">Audit</Link>
				</Nav.Item>
				<Nav.Item>
					<Collapsible
						className="inside-collapsible"
						trigger="Financial Planning"
					>
						<Nav.Item className="inside-collapsible-menu">
							<Link to="/our-services/financial-planning">
								Financial Planning
							</Link>
						</Nav.Item>
						<Nav.Item className="inside-collapsible-menu">
							<Link to="/financial-planning-news">Latest News</Link>
						</Nav.Item>
					</Collapsible>
				</Nav.Item>
				<Nav.Item>
					<Link to="/our-services/loans">Loans</Link>
				</Nav.Item>
				<Nav.Item>
					<Collapsible
						className="inside-collapsible"
						trigger="Cloud Accounting"
					>
						<Nav.Item className="inside-collapsible-menu">
							<Link to="/our-services/cloud-accounting">Cloud Accounting</Link>
						</Nav.Item>
						<Nav.Item className="inside-collapsible-menu">
							<Link to="/our-services/xero">Xero</Link>
						</Nav.Item>
						<Nav.Item className="inside-collapsible-menu">
							<Link to="/our-services/quickbooks">Quickbooks</Link>
						</Nav.Item>
						<Nav.Item className="inside-collapsible-menu">
							<Link to="/our-services/myob">MYOB</Link>
						</Nav.Item>
					</Collapsible>
				</Nav.Item>
			</Collapsible>

			<Collapsible trigger="Resources">
				<Nav.Item>
					<Link to="/resources/client-portal">Client Portal</Link>
				</Nav.Item>
				<Nav.Item>
					<Link to="/latest-videos">Recorded Webinars</Link>
				</Nav.Item>
				<Nav.Item>
					<Link to="/resources/complimentary-resources">
						Complimentary Resources
					</Link>
				</Nav.Item>
				<Nav.Item>
					<Link to="/resources/tax-checklists">Tax Checklist</Link>
				</Nav.Item>
				<Nav.Item>
					<Link to="/resources/property-tax-tools-app">
						Property Tax Tools APP
					</Link>
				</Nav.Item>
			</Collapsible>
			<Collapsible trigger="News">
				<Nav.Item>
					<Link to="/latest-news">Latest News</Link>
				</Nav.Item>
				<Nav.Item>
					<Link to="/news/newsletter">Subscribe</Link>
				</Nav.Item>
			</Collapsible>
			<Nav.Item className="outside-collapsible">
				<Link to="/upcoming-events">Upcoming Events</Link>
			</Nav.Item>
			<Collapsible trigger="Community">
				<Nav.Item>
					<Link to="/community/community">Community</Link>
				</Nav.Item>
				<Nav.Item>
					<Link to="/community/sydney-children-hospital">
						The Sydney Children's Hospital 2018 Toy Drive
					</Link>
				</Nav.Item>
			</Collapsible>
			<Nav.Item className="outside-collapsible">
				<Link to="/blog">Blog</Link>
			</Nav.Item>
			<Nav.Item className="outside-collapsible">
				<Link to="/testimonials">Testimonials</Link>
			</Nav.Item>
			<Nav.Item className="outside-collapsible">
				<Link to="/careers">Careers</Link>
			</Nav.Item>
			<Nav.Item className="outside-collapsible">
				<Link to="/contact">Contact Us</Link>
			</Nav.Item>
		</Nav>
		<div className="credentials_nav">
			<ul className="social-sidebar-topright">
				<li>
					<a
						href="https://www.linkedin.com/company/wsc-group?trk=tyah&amp;trkInfo=tas%3AWSC+Group%2Cidx%3A1-1-1"
						target="_blank"
						rel="noopener noreferrer"
						style={{ opacity: `1`, transform: `matrix(1, 0, 0, 1, 0, 0)` }}
					>
						<i aria-hidden="true" className="fa fa-linkedin"></i>
					</a>
				</li>
				<li>
					<a
						href="https://www.facebook.com/wsc.group.aus"
						target="_blank"
						rel="noopener noreferrer"
						style={{ opacity: `1`, transform: `matrix(1, 0, 0, 1, 0, 0)` }}
					>
						<i aria-hidden="true" className="fa fa-facebook"></i>
					</a>
				</li>
				<li>
					<a
						href="https://plus.google.com/+WscgroupAus"
						target="_blank"
						rel="noopener noreferrer"
						style={{ opacity: `1`, transform: `matrix(1, 0, 0, 1, 0, 0)` }}
					>
						<i aria-hidden="true" className="fa fa-google-plus"></i>
					</a>
				</li>
				<li>
					<a
						href="https://www.youtube.com/channel/UC1TGGfs2nQyPHSADVPA2ghQ"
						target="_blank"
						rel="noopener noreferrer"
						style={{ opacity: `1`, transform: `matrix(1, 0, 0, 1, 0, 0)` }}
					>
						<i aria-hidden="true" className="fa fa-youtube-play"></i>
					</a>
				</li>
			</ul>
			<p
				className="resrv"
				style={{ opacity: `1`, transform: `matrix(1, 0, 0, 1, 0, 0)` }}
			>
				© Copyright 2017 | WSC Group
			</p>
		</div>
	</div>
)
