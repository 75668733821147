import React from 'react'
import { Link } from 'gatsby'

import Sticky from 'react-sticky-el'
import SideBarMenu from './Sidebar'

import styl from './header.module.css'
import Logo from '../images/wsc_logo_small.png'
import emailIcon from '../images/email-icon.png'
import callIcon from '../images/call-btn.png'
import clientIcon from '../images/client.png'
import consIcon from '../images/free-cons-icon.png'
import userIcon from '../images/user-icon.png'

const Header = () => (
  <header className={styl.headerAbsolute}>
    <Sticky
      className={styl.stickyWrapper}
      stickyClassName={styl.isSticky}
      stickyStyle={{ transform: 'unset', zIndex: '2' }}
    >
      <div id="head_top_det">
        <div className="container" id="container-custom">
          <div className="row">
            <div className="col-lg-5 col-sm-5 col-xs-5 left_head text-left">
              <ul className="left-head-detcnt">
                <li>
                  <img src={emailIcon} alt="email icon" />
                  <a href="mailto:info@wscgroup.com.au">info@wscgroup.com.au</a>
                </li>
                <li>
                  <img src={callIcon} alt="call button" />
                  <a href="tel:1300 365 125">1300 365 125</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-7 col-sm-7 col-xs-7 right_head text-right">
              <ul className="rght_head">
                <li className="freeconsltaion">
                  <a href="https://cloud1.sagehandisoft.com.au/ClientPortal/Account/Login/WOOD0024">
                    <img src={clientIcon} alt="client icon" />
                    Client Portal{' '}
                  </a>
                </li>
                <li className="freeconsltaion" id="consult">
                  <Link to="/about-us/book-a-free">
                    <img src={consIcon} alt="free cons icon" />
                    Free Consultation
                  </Link>
                </li>
                <li className="user d-none">
                  <a href="/">
                    <img src={userIcon} alt="user icon" />
                    LOGIN
                  </a>
                </li>
                <li className="socialitem">
                  <a
                    href="https://www.linkedin.com/company/wsc-group/"
                    target="_blank"
                    style={{
                      opacity: 1,
                      transform: `matrix(1, 0, 0, 1, 0, 0)`,
                    }}
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-linkedin" aria-hidden="true" />
                  </a>
                </li>
                <li className="socialitem">
                  <a
                    href="https://www.facebook.com/wsc.group.aus"
                    target="_blank"
                    style={{
                      opacity: 1,
                      transform: `matrix(1, 0, 0, 1, 0, 0)`,
                    }}
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-facebook" aria-hidden="true" />
                  </a>
                </li>
                <li className="socialitem">
                  <a
                    href="https://plus.google.com/+WscgroupAus"
                    target="_blank"
                    style={{
                      opacity: 1,
                      transform: `matrix(1, 0, 0, 1, 0, 0)`,
                    }}
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-google-plus" aria-hidden="true" />
                  </a>
                </li>
                <li className="socialitem" id="youtube">
                  <a
                    href="https://www.youtube.com/channel/UC1TGGfs2nQyPHSADVPA2ghQ"
                    target="_blank"
                    style={{
                      opacity: 1,
                      transform: `matrix(1, 0, 0, 1, 0, 0)`,
                    }}
                    rel="noopener noreferrer"
                  >
                    <i className="fa fa-youtube-play" aria-hidden="true" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className={styl.menuContainer}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-6">
              <Link className={styl.logoLink} to="/">
                <img className={styl.menuLogo} src={Logo} alt="" />
              </Link>
            </div>
            <div className="col-6">
              <SideBarMenu />
            </div>
          </div>
        </div>
      </div>
    </Sticky>
  </header>
)

export default Header
